import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const createSubscriptionDebug = createAsyncThunk(
    "createSubscription",
    async (data) => {
        try {
            console.log("here")
            const response = await AxiosInstance.post(
                `/subscription/v3/subscription-api-debug`,
                data
            );
            return response.data;
        } catch (error) {
            console.log("error",error)
            if (error.response) {
                throw error.response.data.message;
            } else {
                throw error;
            }
        }
    }
);

const createSubscriptionDebugSlice = createSlice({
    name: "createSubscription",
    initialState: {
        isLoading: false,
        createSubscriptionData: null,
        isError: false,
        errorMessage: "",
    },
    extraReducers: {
        [createSubscriptionDebug.pending]: (state) => {
            state.isLoading = true;
        },
        [createSubscriptionDebug.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createSubscriptionData = action.payload;
            state.isError = false;
            state.errorMessage = "";
        },
        [createSubscriptionDebug.rejected]: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.error.message;
        },
    },
});

export default createSubscriptionDebugSlice.reducer;
