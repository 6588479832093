import React from "react";
import Home from "../home";
import { Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotFound from "../../assets/notFound/location.png";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";

const NotFoundComponent = () => {
  const [openPopup, setOpenPopup] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const handleCloseModal = () => {
    setOpenPopup(false);
    navigate(`/${lang}/`);
  };

  const { t } = useTranslation();
  return (
    <>
      <Home />
      <Modal
        open={openPopup}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
      >
        <div className="login-modal-section">
          <div className="opps-text" style={{ textAlign: "center" }}>
            <p style={{ fontSize: "65px", margin: "0" }}>
              {t("NOT_FOUND_OPPS")}
            </p>
            <p style={{ fontSize: "37px", margin: "0" }}>
              {t("NOT_FOUND_TEXT")}
            </p>
            <img
              src={NotFound}
              alt="Not Found"
              style={{ width: "60%", height: "auto" }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotFoundComponent;
