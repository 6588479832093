import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const updateSetting = createAsyncThunk("updateSetting", async (data) => {
  try {
    const response = await AxiosInstance.put(`/user/settings`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const updateSettingSlice = createSlice({
  name: "updateSetting",
  initialState: {
    isLoading: false,
    updateSettingData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [updateSetting.pending]: (state) => {
      state.isLoading = true;
    },
    [updateSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.updateSettingData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [updateSetting.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default updateSettingSlice.reducer;
