import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const googleLogin = createAsyncThunk("googleLogin", async (data) => {
  try {
    const response = await AxiosInstance.post(`/user/google/login`, {
      payload: {
        email: data.email?.toLowerCase(),
        google_id: data.google_id,
      }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const googleLoginSlice = createSlice({
  name: "googleLogin",
  initialState: {
    isLoading: false,
    loginData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [googleLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [googleLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loginData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [googleLogin.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default googleLoginSlice.reducer;