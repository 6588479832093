import { createContext, useContext, useEffect, useRef, useState } from "react";
import AppLayout from "./layouts";
import AppProvider from "./provider";
import AppRoutes from "./routes";
import "./scss/style.scss";
import "react-phone-input-2/lib/style.css";
import "./language";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-loading-skeleton/dist/skeleton.css";
import { google_client_id } from "./environment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Cookies, useCookies } from "react-cookie";

const AppContextInput = createContext();

export const useAppContextInput = () => useContext(AppContextInput);
function App() {
  const inputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const [plan, setPlan] = useState("trial");
  const handleInputFocus = (props) => {
    setPlan(props);
    if (window.screen.width > 767) {
      inputRef.current.focus();
    } else {
      mobileInputRef.current.focus();
    }
  };
  // Logout function
  const cookies = new Cookies();
  // eslint-disable-next-line
  const [token, setToken, removeToken] = useCookies(["token"]);
  // eslint-disable-next-line
  const [roles, setRole, removeRole] = useCookies(["role"]);
  const handleLogout = () => {
    removeToken(["token"]);
    removeRole(["role"]);
    const cookieKeys = Array.isArray(cookies.getAll())
      ? cookies.getAll()
      : Object.keys(cookies.getAll());
    cookieKeys.forEach((key) => {
      if (key !== "currency" && key !== "lang" && key !== "langlogo") {
        cookies.remove(key, { path: "/" });
      }
    });
    window.location.reload("/");
  };
  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <AppProvider>
        <AppContextInput.Provider
          value={{
            inputRef,
            mobileInputRef,
            handleInputFocus,
            plan,
            handleLogout,
          }}
        >
          <AppLayout>
            <AppRoutes />
          </AppLayout>
        </AppContextInput.Provider>
      </AppProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
