/* global gtag */
import React, { useState, useEffect } from "react";
import "./pageBanner.scss";
import { Cookies } from "react-cookie";
import {
  SearchOutlined as AiOutlineSearch,
  CloseOutlined as IoMdClose,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import bannerIphone from "../../assets/home/banner-iphone.png";
import { Form, Input, Skeleton } from "antd";
import {
  parsePhoneNumberFromString,
  AsYouType,
  getCountryCallingCode,
} from "libphonenumber-js";

import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { calculateCursorPosition } from "../../utils/commonUtils";

const PageBannerContant = (props) => {
  const {
    url,
    fx,
    from,
    inputRef,
    mobileInputRef,
    plan,
    sendDetailsData,
    signUpUrl,
  } = props;
  const utm_term = fx?.replaceAll("-", " ");
  const cookies = new Cookies();
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCodeinput, setCountryCode] = useState("ca");
  const [numberValidate, setNumberValidate] = useState("");
  const [dialCode, setDialCode] = useState("+1");
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [finalFormattedNumber, setFinalFormattedNumber] = useState(
    location.pathname
  );
  const token = cookies.get("token");
  const lang = cookies.get("lang");
  const [renderRTLClass, setRenderRTLClass] = useState(
    ["ar", "he"].includes(window.location.pathname.split("/")[1])
  );
  useEffect(() => {
    setRenderRTLClass(
      ["ar", "he"].includes(window.location.pathname.split("/")[1])
    );
  }, [window.location.pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname);
      setNumberValidate("");
    }
  }, [location.pathname]);
  useEffect(() => {
    if (sendDetailsData) {
      setDialCode(sendDetailsData.data.country_calling_code);
      setCountryCode(sendDetailsData.data.country_code.toLowerCase());
    }
    // eslint-disable-next-line
  }, [sendDetailsData]);

  const handleSetNumber = (value, country) => {
    setDialCode(country.dialCode);
    setNumberValidate("");
    setCountryCode(country.countryCode);
    value = formatPhoneNumber(phoneNumber, country.countryCode.toUpperCase());
    setPhoneNumber(value);
  };

  const handleSetPhoneNumber = (event) => {
    setNumberValidate("");
    let { value } = event.target;

    const input = event.target.value;
    const cursorPosition = event.target.selectionStart;
    const formatter = new AsYouType(countryCodeinput.toUpperCase());
    let formattedPhoneNumber;
    let parsedNumber;
    if (value.length > 5) {
      parsedNumber = parsePhoneNumberFromString(value, {
        defaultCountry: countryCodeinput.toUpperCase(),
      });
    }

    if (parsedNumber && parsedNumber.country && value.length > 5) {
      setCountryCode(parsedNumber.country.toLowerCase());
    }

    // Detect if the user is deleting characters
    const isDeleting = event.nativeEvent.inputType === "deleteContentBackward";

    // Only format the phone number if the user is not deleting characters
    if (!renderRTLClass) {
      value = formatPhoneNumber(
        value,
        parsedNumber && parsedNumber.country,
        parsedNumber
      );
      formattedPhoneNumber = formatter.input(value);
      setPhoneNumber(formattedPhoneNumber);
      requestAnimationFrame(() => {
        const newCursorPosition = calculateCursorPosition(
          input,
          formattedPhoneNumber,
          cursorPosition,
          isDeleting
        );
        if (inputRef.current) {
          inputRef.current.setSelectionRange(
            newCursorPosition,
            newCursorPosition
          );
        }
      });
    } else {
      setPhoneNumber(value);
      setFinalFormattedNumber(value);
    }

    // Set the formatted value to state
  };

  useEffect(() => {
    const input = document.getElementById("phone_input");
    if (input) {
      input.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  const formatPhoneNumber = (
    value,
    countryCode = countryCodeinput.toUpperCase()
  ) => {
    // Use AsYouType to format the number according to the country code

    const formatter = new AsYouType(countryCode);
    let adjustedValue = value;

    const formattedNumber = formatter.input(adjustedValue);
    // Get the country calling code
    const countryCallingCode = getCountryCallingCode(countryCode);

    setDialCode(countryCallingCode);
    const countryCallingCodeWithPlus = `+${countryCallingCode}`;

    // Ensure the formatted number does not start with the country calling code
    let finalFormattedNumber = formattedNumber;
    if (formattedNumber.startsWith(countryCallingCodeWithPlus)) {
      finalFormattedNumber = formattedNumber
        .replace(countryCallingCodeWithPlus, "")
        .trim();
    }
    finalFormattedNumber = finalFormattedNumber.replace(/[()]/g, "").trim();

    // Check if the number starts with brackets and the second character is zero
    if (
      finalFormattedNumber.startsWith("(") ||
      finalFormattedNumber.includes("-")
    ) {
      finalFormattedNumber = finalFormattedNumber.replace(/-/g, "").trim();
    }

    setFinalFormattedNumber(finalFormattedNumber);
    return finalFormattedNumber;
  };

  const navigate = useNavigate();
  const pageVersion = url === "track" || url === "trace" ? "b" : "w";

  const handleNavigate = () => {
    const parsedNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCodeinput.replace("+", "").toUpperCase()
    );
    let trimmedNumber = finalFormattedNumber.trim();

    if (trimmedNumber.startsWith(dialCode)) {
      trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
    }

    if (phoneNumber && phoneNumber !== "0") {
      // parsedNumber.country === countryCodeinput.replace("+", "").toUpperCase()
      if (
        parsedNumber &&
        parsedNumber.isValid() &&
        parsedNumber.country === countryCodeinput.replace("+", "").toUpperCase()
      ) {
        gtag("event", "track_phone_number", {
          phoneNumber: parsedNumber,
          page_version: pageVersion,
        });
        if (token) {
          navigate(`/${lang}/dash`);
        } else {
          navigate(
            `/${lang}/${
              signUpUrl
                ? "tracking/"
                : url === "track" || url === "trace"
                ? `${url}/`
                : ""
            }${url === "spreedlyWithSignup" ? "signup" : "sign-up"}${
              plan === "subscription" ? `/${plan}` : ""
            }?D=${dialCode}&n=${parsedNumber.nationalNumber}`,
            {
              state: {
                phoneNumber: trimmedNumber,
              },
            }
          );
        }
      } else {
        setNumberValidate(t("ERROR_VALID_NUMBER_HOME"));
      }
    } else {
      setNumberValidate(t("ERROR_ENTER_NUMBER_HOME"));
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "+") {
      event.preventDefault();
      setPhoneNumber((prevNumber) => (prevNumber ? `+${prevNumber}` : "+"));
    }
  };

  return (
    <div className="Locate-Any-Phone">
      <div className="wid-home-banner">
        <div className="df-first-section">
          <div className="left-contant">
            {from === "track" || from === "trace" ? (
              <>
                <div
                  className={`right-text ${
                    lang !== "en" ? "heading-title" : "head-title"
                  }`}
                >
                  <p>
                    {utm_term
                      ? utm_term.charAt(0).toUpperCase() +
                        utm_term.slice(1, 1111)
                      : t("BANNER_TITLE")}
                  </p>
                  {/* <p>{utm_term ? utm_term.charAt(0) : t("BANNER_TITLE")}</p> */}
                </div>
                <div className="right-text head-semi-title">
                  {t("BANNER_INPUT_LABEL")}
                </div>
                <div className="wrap-right-input-error">
                  <Form>
                    <div className="right-text head-input">
                      <PhoneInput
                        country={countryCodeinput || "ca"}
                        onChange={handleSetNumber}
                        placeholder=""
                        className={"desktop-input"}
                        countryCodeEditable={true}
                        autoFormat={true}
                        inputProps={{
                          name: "phone",
                          className: "phone-input-field",
                        }}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                          border: " #0000002b 1px solid",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                        onEnterKeyPress={handleNavigate}
                      />

                      <PhoneInput
                        country={countryCodeinput || "ca"}
                        onChange={handleSetNumber}
                        placeholder=""
                        className={"mobile-input"}
                        countryCodeEditable={true}
                        autoFormat={true}
                        inputProps={{
                          name: "phone",
                          className: "phone-input-field",
                        }}
                        onEnterKeyPress={handleNavigate}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                          border: " #0000002b 1px solid",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                      />
                      <Input
                        ref={inputRef}
                        className="desktop-input-nd desk"
                        onChange={handleSetPhoneNumber}
                        placeholder={t("BANNER_INPUT")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        value={phoneNumber}
                        id="phone_input"
                      />

                      <Input
                        ref={mobileInputRef}
                        className="desktop-input-nd mobile"
                        onChange={handleSetPhoneNumber}
                        placeholder={t("BANNER_INPUT_MOBILE")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        value={phoneNumber}
                      />
                      <button
                        className="input-suffix hl_cta_wrap"
                        onClick={handleNavigate}
                        type="submit"
                      >
                        <AiOutlineSearch
                          style={{
                            fontSize: 23,
                          }}
                        />
                        <span className="span-text">{t("LOCATE")}</span>
                      </button>
                    </div>
                  </Form>

                  {numberValidate && (
                    <p className="invelid-number-error">
                      {numberValidate}{" "}
                      <IoMdClose onClick={() => setNumberValidate("")} />{" "}
                    </p>
                  )}
                </div>
                <div className="right-text head-semi-title">
                  {t(
                    url === "track" || url === "trace"
                      ? "BANNER_TEXT.B"
                      : "BANNER_TEXT"
                  )}{" "}
                  {utm_term
                    ? utm_term.charAt(0).toUpperCase() + utm_term.slice(1, 1111)
                    : ""}
                </div>
              </>
            ) : from === "none" ? (
              <>
                <div
                  className={`right-text ${
                    lang !== "en" ? "heading-title" : "head-title"
                  }`}
                >
                  <p>{t("BANNER_TITLE")}</p>
                  {/* <p>{utm_term ? utm_term.charAt(0) : t("BANNER_TITLE")}</p> */}
                </div>
                <div className="right-text head-semi-title">
                  {t("BANNER_INPUT_LABEL")}
                </div>
                <div className="wrap-right-input-error">
                  <Form>
                    <div className="right-text head-input">
                      <PhoneInput
                        country={countryCodeinput || "ca"}
                        onChange={handleSetNumber}
                        placeholder=""
                        className={"desktop-input"}
                        countryCodeEditable={true}
                        autoFormat={true}
                        inputProps={{
                          name: "phone",
                          className: "phone-input-field",
                        }}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                          border: " #0000002b 1px solid",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                        onEnterKeyPress={handleNavigate}
                      />

                      <PhoneInput
                        country={countryCodeinput || "ca"}
                        onChange={handleSetNumber}
                        placeholder=""
                        className={"mobile-input"}
                        autoFormat={true}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                          border: " #0000002b 1px solid",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                      />

                      <Input
                        className="desktop-input-nd desk"
                        onChange={handleSetPhoneNumber}
                        placeholder={t("BANNER_INPUT")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        ref={inputRef}
                        value={phoneNumber}
                        id="phone_input"
                      />

                      <Input
                        className="desktop-input-nd mobile"
                        onChange={handleSetPhoneNumber}
                        placeholder={t("BANNER_INPUT_MOBILE")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        ref={mobileInputRef}
                        value={phoneNumber}
                      />
                      <button
                        className="input-suffix hl_cta_wrap"
                        onClick={handleNavigate}
                        type="submit"
                      >
                        <AiOutlineSearch
                          style={{
                            fontSize: 23,
                          }}
                        />
                        <span className="span-text">{t("LOCATE")}</span>
                      </button>
                    </div>
                  </Form>

                  {numberValidate && (
                    <p className="invelid-number-error">
                      {numberValidate}{" "}
                      <IoMdClose onClick={() => setNumberValidate("")} />{" "}
                    </p>
                  )}
                </div>
                <div className="right-text head-semi-title">
                  {t(
                    url === "track" || url === "trace"
                      ? "BANNER_TEXT.B"
                      : "BANNER_TEXT"
                  )}
                </div>
              </>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
          </div>
          <div className="right-contant">
            <div className="banner-img">
              <img
                src={bannerIphone}
                alt="Banner"
                height={1000}
                width={700}
                loading="lazy"
              />
              <p className="location-banner-img-text">
                {t("BANNER_IMAGE_LOCATION_FOUND")}
              </p>
              <p className="location-banner-img-desc">
                {t("BANNER_IMAGE_TEXT")}
              </p>
              <p className="location-banner-img-view">
                {t("BANNER_IMAGE_VIEW_IT")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
    sendDetailsData: state.sendDetailsData.getSendDetailsData,
  };
};

export default connect(mapStateToProps, null)(PageBannerContant);
